import { Badge } from '@windmill/react-ui';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeledTextArea from 'src/components/_common/LabeledTextArea/LabeledTextArea';
import Comment, { CommentType } from './Comment';
import { FormAnswerComment } from '../../FormAnswers';

interface FormQuestionDisagreementsProps {
  showNewDisagreement?: boolean,
  disagreementComment?: string,
  changeDisagreementComment: (comment: string) => void,
  teacherComment?: FormAnswerComment,
  supervisorComment?: FormAnswerComment,
  studentComment?: FormAnswerComment,
  teacherMidTermComment?: FormAnswerComment,
  supervisorMidTermComment?: FormAnswerComment,
  studentMidTermComment?: FormAnswerComment,
  activeColor?: string,
  disagreementBadgeText: string,
  noTypePicker?: boolean,
  isMidSemester: boolean,
  onBlur: () => void,
  othersComments: Array<FormAnswerComment | undefined> | undefined,
  othersMidTermComments: Array<FormAnswerComment | undefined> | undefined,
}

const FormQuestionDisagreements = ({
  showNewDisagreement,
  teacherComment,
  supervisorComment,
  studentComment,
  teacherMidTermComment,
  supervisorMidTermComment,
  studentMidTermComment,
  activeColor,
  disagreementComment,
  changeDisagreementComment,
  disagreementBadgeText,
  noTypePicker,
  isMidSemester,
  onBlur,
  othersComments,
  othersMidTermComments,
}: FormQuestionDisagreementsProps) => {
  const { formatMessage } = useIntl();

  function showPreviousCommentsForMidTermAnswers() {
    if (isMidSemester) return false;
    return teacherMidTermComment || supervisorMidTermComment || studentMidTermComment;
  }

  return (
    <>
      {((showNewDisagreement || noTypePicker) || teacherComment || supervisorComment || studentComment)
        && (
          <div className={!noTypePicker ? 'mt-8' : ''}>
            {(showNewDisagreement || noTypePicker)
              && (
                <div className="flex py-4 border-lightGray items-center">
                  <Badge className={classNames(
                    `bg-${activeColor}`,
                    `text-${activeColor}Text`,
                    'text-xs font-bold mr-2 h-full',
                  )}
                  >
                    {disagreementBadgeText}
                  </Badge>
                  <LabeledTextArea
                    onValueChangeHandler={changeDisagreementComment}
                    value={disagreementComment || ''}
                    className="w-full"
                    labelText={formatMessage({ id: 'comment' })}
                    onBlur={onBlur}
                  />
                </div>
              )}
          </div>
        )}
      <div>
        {teacherComment?.comment && (
          <Comment type={CommentType.Teacher} userId={teacherComment.userId!} commentDate={(teacherComment.updatedAt ? teacherComment.updatedAt : teacherComment.createdAt!)}>{teacherComment.comment}</Comment>
        )}
        {supervisorComment?.comment && (
          <Comment type={CommentType.Supervisor} userId={supervisorComment.userId!} commentDate={(supervisorComment.updatedAt ? supervisorComment.updatedAt : supervisorComment.createdAt!)}>{supervisorComment.comment}</Comment>
        )}
        {studentComment?.comment && (
          <Comment type={CommentType.Student} userId={studentComment.userId!} commentDate={(studentComment.updatedAt ? studentComment.updatedAt : studentComment.createdAt!)}>{studentComment.comment}</Comment>
        )}
        {othersComments && othersComments.map(comment => (
          comment?.comment ? <Comment type={CommentType.Supervisor} userId={comment.userId!} commentDate={(comment.updatedAt ? comment.updatedAt : comment.createdAt!)}>{comment.comment}</Comment> : undefined
        ))}
        {showPreviousCommentsForMidTermAnswers() && (
          <>
            <h5>
              <FormattedMessage id="commentsFromMidTerm" />
            </h5>
            {teacherMidTermComment?.comment && (
            <Comment type={CommentType.Teacher} userId={teacherMidTermComment.userId!} commentDate={(teacherMidTermComment.updatedAt ? teacherMidTermComment.updatedAt : teacherMidTermComment.createdAt!)}>{teacherMidTermComment.comment}</Comment>
            )}
            {supervisorMidTermComment?.comment && (
            <Comment type={CommentType.Supervisor} userId={supervisorMidTermComment.userId!} commentDate={(supervisorMidTermComment.updatedAt ? supervisorMidTermComment.updatedAt : supervisorMidTermComment.createdAt!)}>{supervisorMidTermComment.comment}</Comment>
            )}
            {studentMidTermComment?.comment && (
            <Comment type={CommentType.Student} userId={studentMidTermComment.userId!} commentDate={(studentMidTermComment.updatedAt ? studentMidTermComment.updatedAt : studentMidTermComment.createdAt!)}>{studentMidTermComment.comment}</Comment>
            )}
            {othersMidTermComments && othersMidTermComments.map(comment => (
              comment?.comment ? <Comment type={CommentType.Supervisor} userId={comment.userId!} commentDate={(comment.updatedAt ? comment.updatedAt : comment.createdAt!)}>{comment.comment}</Comment> : undefined
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default FormQuestionDisagreements;
