import { useParams, useHistory } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { useIntl, FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import ClassesContext from 'src/hooks/useClasses';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import classnames from 'classnames';
import { RoleTypes } from 'src/models/roles';
import AddUsersToClassModal from 'src/pages/Teacher/TeacherClass/AddUsersToClassModal/AddUsersToClassModal';
import ClassFormsTable from 'src/components/ClassFormsTable/ClassFormsTable';
import Button from 'src/components/_common/Button/Button';
import ClassDates from 'src/components/ClassDates/ClassDates';
import { sortSimpleUserByDisplayNameComparer } from 'src/utils/userUtils';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Badge from 'src/components/_common/Badge/Badge';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';
import { SimpleUser } from 'src/models/classes';
import SupervisorModal from './SupervisorModal/SupervisorModal';

const TeacherClass = () => {
  const {
    getCurrentClass,
    currentClass,
    currentClassLoaded,
    usersForClass,
    getUsersForClass,
    addSupervisorToClass,
    getMyStudents,
    myStudents,
    myStudentsLoaded,
    joinClassAsTeacher,
    leaveClassAsTeacher,
    isSaving,
  } = ClassesContext.useContainer();

  const { user, getUser } = LoggedInUserContext.useContainer();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [supervisorModalOpen, setSupervisorModalOpen] = useState<boolean>(false);

  const { classId } = useParams<{ classId: string }>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    getCurrentClass(+classId);
    getUsersForClass(+classId);
    getMyStudents(+classId);
  }, []);

  const userIsTeacherForClass = !!user?.teacherClasses?.includes(+classId);

  const handleClick = () => {
    if (userIsTeacherForClass) {
      leaveClassAsTeacher(+classId, getUser);
    } else {
      joinClassAsTeacher(+classId, getUser);
    }
  };

  const handleOpenModal = (_roleType: RoleTypes) => {
    if (_roleType === RoleTypes.Advisor) {
      setSupervisorModalOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  const navigateToStudent = (student: SimpleUser) => history.push(`/teacher/class/${classId}/student/${student.id}`);
  const navigateToSupervisor = (supervisor: SimpleUser) => history.push(`/teacher/class/${classId}/advisor/${supervisor.id}`);

  if (!currentClassLoaded && !myStudentsLoaded && user?.id) return <Spinner />;

  return (
    <>
      <PageHeader title={currentClass?.subject?.subjectCode} subTitle={currentClass?.subject?.name}>
        <Badge>{currentClass?.period?.name}</Badge>
      </PageHeader>
      <ClassDates />
      <ClassFormsTable baseRoute="/teacher" currentClass={currentClass} subjectCode={currentClass?.subject?.subjectCode} />
      {userIsTeacherForClass
        && (
        <List
          className="pt-8"
          title={formatMessage({ id: 'your-students' })}
          emptyListMessage={formatMessage({ id: 'no-students-assigned' }, { subjectCode: currentClass?.subject?.subjectCode })}
          listIsEmpty={!myStudents?.length}
          titleButtonAriaLabel={formatMessage({ id: 'addStudent' })}
          onTitleClick={() => handleOpenModal(RoleTypes.Student)}
        >
          {myStudents?.sort(sortSimpleUserByDisplayNameComparer).map(student => (
            <ListItem key={student.id} onClick={() => navigateToStudent(student)}>
              {student.displayName}
            </ListItem>
          ))}
        </List>
        )}
      <List
        className="pt-8"
        title={formatMessage({ id: 'supervisors' })}
        titleButtonAriaLabel={formatMessage({ id: 'addSupervisor' })}
        onTitleClick={() => handleOpenModal(RoleTypes.Advisor)}
        emptyListMessage={formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'supervisors' }).toLowerCase() })}
        listIsEmpty={!usersForClass?.advisors?.length}
      >
        {usersForClass?.advisors.sort(sortSimpleUserByDisplayNameComparer).map(supervisor => (
          <ListItem key={supervisor.id} onClick={() => navigateToSupervisor(supervisor)}>
            {supervisor.displayName}
          </ListItem>
        ))}
      </List>
      <div className="text-center mt-8">
        <Button
          onClick={() => handleClick()}
          className={classnames({ 'bg-danger': userIsTeacherForClass }, 'self-center')}
          isSaving={isSaving}
        ><FormattedMessage id={userIsTeacherForClass ? 'leave-class' : 'join-class'} />
        </Button>
      </div>
      {modalOpen
        && (
          <AddUsersToClassModal
            isOpen={!!modalOpen}
            onClose={() => setModalOpen(false)}
            classId={+classId}
          />
        )}
      {supervisorModalOpen && currentClass
        && (
          <SupervisorModal
            addSupervisor={addSupervisorToClass}
            currentClass={currentClass}
            onClose={() => setSupervisorModalOpen(false)}
            supervisorModalOpen={supervisorModalOpen}
            isSaving={isSaving}
          />
        )}
    </>
  );
};

export default TeacherClass;
