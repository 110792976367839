export const uniqBy = (arr: Array<any>, predicate: any) => {
  const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate];

  return [...arr.reduce((map, item) => {
    const key = (item === null || item === undefined)
      ? item : cb(item);

    // eslint-disable-next-line no-unused-expressions
    map.has(key) || map.set(key, item);

    return map;
  }, new Map()).values()];
};

export default uniqBy;
